@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&display=swap');
* {
    font-family: 'Kumbh Sans', sans-serif;
}

html {
    scroll-behavior: initial !important;
}

body {
    font-size: 20px;
}

:root {
    --bs-primary: #9ADBDA;
    --bs-dark: #002D3B;
    --bs-primary-rgb: rgb(154, 219, 218);
    --bs-dark-rgb: rgb(0, 45, 59);
    --sub-primary-orange: #ED6F44;
    --green-color: #A1D955;
    --orange-gradient: linear-gradient(180deg, rgba(237, 190, 115, 1) 0%, rgba(237, 111, 68, 1) 100%);
}

.home-page-back {
    min-height: 90vh;
    width: 100%;
    background-color: var(--bs-primary) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.prefooter {
    background-color: var(--bs-primary) !important;
}

.home-pals-logo {
    height: 360px;
}

.home-launch-text {
    font-size: 4.75em;
    font-weight: bolder;
    letter-spacing: 9.5px;
    color: var(--bs-body-bg);
    text-align: center;
    margin-top: 130px !important;
}

.home-instagram-text {
    position: absolute;
    bottom: 34px;
    font-size: 2.45em;
    font-weight: bolder;
    color: var(--bs-body-bg);
}

.instagram-logo {
    height: 55px;
    width: 55px;
}

.cursor-pointer {
    cursor: pointer;
}

.follow-us-instagram {
    font-size: 54px;
    font-weight: bolder;
    color: var(--bs-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.title-banner-container {
    height: 25vh;
    background-color: var(--bs-primary-rgb);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.6em;
    font-weight: bolder;
    letter-spacing: 9.5px;
    color: var(--bs-body-bg);
}

.wrap-reverse {
    flex-wrap: wrap-reverse;
}

.home-follow-us {
    min-height: 10vh;
    background-color: var(--bs-primary-rgb);
}

.vh-100 {
    height: 100vh !important;
}

.policy-text {
    font-size: 22px;
    color: var(--bs-dark);
}

.thank-you-text {
    font-size: 1.7em;
    text-align: center;
}

.background-ellipse-position {
    position: absolute;
    top: 0px;
    left: 0%;
    width: 35%;
    height: 50%;
    z-index: 0;
}

.top-banner-heading {
    font-size: 68px !important;
    font-weight: bolder;
    letter-spacing: 6.8px;
    color: var(--bs-primary);
    line-height: 75px;
    z-index: 1;
    margin-top: 32px;
}

.top-banner-subtext {
    font-size: 30px !important;
    line-height: 40px !important;
    z-index: 1;
    color: var(--bs-dark)
}

.top-banner-subtext-caption {
    font-weight: bold;
    font-size: 28px;
    color: var(--sub-primary-orange);
    letter-spacing: 2.8px;
    line-height: 35px;
    z-index: 1;
}

.watch-demo-btn {
    letter-spacing: 2.8px;
    font-weight: bold;
    border-radius: 50px;
    font-size: 28px;
    max-width: 363px;
    width: 100%;
    height: 68px;
    padding: auto 0px;
    border: none;
    background: rgb(237, 190, 115);
    background: var(--orange-gradient);
    z-index: 1;
}
.banner-btn-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.custom-btn {
    background-image: var(--orange-gradient);
    letter-spacing: 2.8px;
    font-weight: bold;
    border-radius: 50px;
    font-size: 28px;
    /* max-width: 363px; */
    /* width: 100%; */
    min-height: 68px;
    padding: auto 0px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    background-color: var(--bs-primary) !important;
    z-index: 1;
    outline: none !important;
    box-shadow: none !important;
    background-repeat: no-repeat;
}

.primary-btn {
    letter-spacing: 2.8px;
    font-weight: bold;
    border-radius: 50px;
    font-size: 28px;
    max-width: 363px;
    width: 100%;
    height: 68px;
    padding: auto 0px;
    border: none;
    background-color: var(--bs-primary) !important;
    z-index: 1;
    outline: none !important;
    box-shadow: none !important;
}

.primary-btn--outlined {
    letter-spacing: 2.8px;
    font-weight: bold;
    border-radius: 50px;
    font-size: 28px;
    max-width: 363px;
    width: 100%;
    height: 68px;
    padding: auto 0px;
    border: 3px solid var(--bs-primary);
    color:rgb(154, 219, 218);
    background: var(--bs-white);
    z-index: 1;
    outline: none !important;
    box-shadow: none !important;
}

.primary-btn--outlined:focus,
.primary-btn--outlined:visited,
.primary-btn--outlined:active,
.primary-btn--outlined:hover {
    border: 3px solid var(--bs-primary);
    color:rgb(154, 219, 218);
    background: var(--bs-white);
    outline: none !important;
    box-shadow: none !important;
}

.banner-mockup-image {
    width: 500px;
    z-index: 1;
}

.head-text {
    font-size: 28px;
    letter-spacing: 2.8px;
    color: var(--sub-primary-orange);
    margin-bottom: 5px !important;
}

.sub-head-text {
    line-height: 60px;
    font-size: 49px;
    font-weight: bold;
    color: var(--bs-dark);
}

.sub-text-peragraph {
    font-size: 22px;
    line-height: 28px;
    margin-top: 10px;
    color: var(--bs-dark);
}

.text-dark {
    color: var(--bs-dark);
}

.card-header-text {
    font-size: 35px;
    font-weight: bolder;
    color: var(--bs-dark);
}

.card-subtext {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    color: var(--bs-dark);
}

.card-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green-color);
    width: 82px;
    height: 82px;
    border-radius: 80px;
    margin-inline: 0;
    transition: all 0.10s ease-in-out 0s
}

.margin-top {
    margin-top: 106px;
}

.border-bg-shadow div {
    transition: all 0.10s ease-in-out 0s
}

.border-bg-shadow {
    border-radius: 10px;
    box-shadow: #00000029 0px 0px 0px;
    background: var(--bs-white);
    transition: all 0.10s ease-in-out 0s
}

.border-bg-shadow :hover .card-icons {
    background-color: var(--bs-white);
    border-radius: 100px;
}

.border-bg-shadow :hover {
    box-shadow: #00000029 0px 3px 6px;
    border-radius: 10px;
    background-color: var(--green-color);
    color: var(--bs-white);
    cursor: default !important;
}

.border-bg-shadow :hover>div {
    box-shadow: 0px 0px 0px;
    color: var(--bs-white);
}

.about-card {
    margin: auto;
    height: 100%;
    padding: 44px 50px;
}

.margin-top-37 {
    margin-top: 37px;
}

.our-mission-list {
    font-size: 24px;
    margin-bottom: 40px;
    color: var(--bs-dark);
    display: flex;
}

.list-style-none {
    list-style: none;
}

.check-mark {
    margin-right: 21px;
}

.map-check-position {
    display: flex;
    justify-content: start;
}

.map-check-my-palace {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-check-my-palace-img {
    width: 997px;
    height: 759px;
}

.blue-circle {
    width: 100%;
    justify-content: start;
    /* width: 150%;
    margin-left: 150px !important; */
}

.merch-available-soon-text {
    margin-top: 28px;
    font-size: 20px;
    font-weight: bold;
    color: var(--bs-dark)
}

.flex-direction-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.pal-bandana-position {
    display: flex;
    justify-content: flex-end;
}

.form-bg-color {
    max-width: 1350px !important;
    background: var(--orange-gradient);
    border-radius: 20px;
    padding-top: 99px;
    padding-bottom: 126px;
}

.form-header-text {
    color: var(--bs-white);
    font-size: 54px;
    font-weight: bold;
    line-height: 62px;
}

.form-subtext {
    color: var(--bs-white);
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 85px;
    font-weight: lighter;
}

::placeholder {
    color: var(--bs-white);
}

.form-name-input {
    display: flex;
    justify-content: flex-end;
}

.form-email-input {
    display: flex;
    justify-content: flex-start;
}

.form-input {
    width: 100%;
    height: 68px;
    border: 0px;
    border-radius: 150px;
    background-color: rgba(255, 255, 255, 0.4);
    font-size: 23px;
    color: var(--bs-white);
    letter-spacing: 2.8px;
    font-weight: bold;
}

.form-message-input {
    width: 100%;
    height: 160px;
    border: 0px;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.4);
    font-size: 23px;
    color: var(--bs-white);
    letter-spacing: 2.8px;
    font-weight: bold;
}

.form-button {
    letter-spacing: 2.8px;
    font-weight: bold;
    border-radius: 50px;
    font-size: 28px;
    width: 363px;
    height: 68px;
    padding: auto 0px;
    border: 3px solid var(--bs-white);
    background: transparent;
    z-index: 1;
    margin-top: 85px;
}

input {
    outline: none !important;
}

textarea {
    outline: none !important;
}

.blue-circle-inner-image {
    position: absolute;
    width: 100%;
}

.form-button:hover {
    background-color: transparent;
    border: 3px solid var(--bs-white);
}

.text-dark {
    color: var(--bs-dark) !important;
}

.header-logo {
    max-width: 157px;
    transition: all 0.2s linear;
}

.bg-primary {
    background-color: var(--bs-primary) !important;
}

.header-menu-item {
    font-size: 22px;
    letter-spacing: 2.2px;
    line-height: 23px;
    color: var(--bs-dark) !important;
    font-weight: bold;
    margin-left: 50px;
    margin-top: 10px;
}

.header-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default !important;
}

.swiper-button-prev {
    background-image: url(../svgs/arrow-left.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}

.swiper-button-next {
    background-image: url(../svgs/arrow-right.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}

.swiper-button-prev::after {
    display: none;
}

.swiper-button-next::after {
    display: none;
}

.about-icon {
    font-size: 37px;
    color: var(--bs-white);
    fill: var(--bs-white) !important;
}

.border-bg-shadow:hover .about-icon {
    color: var(--green-color) !important;
    fill: var(--green-color) !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.responsive-navbar-nav {
    border: none !important;
    box-shadow: none !important;
}

.navbar-light .navbar-toggler {
    color: transparent !important;
    border-color: transparent !important;
}

.form-button:focus {
    background-color: transparent !important;
    border: 3px solid var(--bs-white) !important;
    box-shadow: none;
}

.form-button:active {
    background-color: transparent !important;
    border: 3px solid var(--bs-white) !important;
    box-shadow: none;
}

.my-2rem {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.sticky-navbar {
    z-index: 99999;
    top: 0px;
    position: fixed;
    width: 100%;
    transition: all 0.2s linear;
}

.sticky-navbar-active {
    background-color: var(--bs-primary) !important;
    box-shadow: 5px -1px 12px -5px grey;
    height: auto !important;
}

.navbar-logo-hover {
    max-width: 120px !important;
}

.banner-container {
    margin-top: 170px;
}

.form-button-valid {
    background-color: var(--bs-white);
    color: var(--sub-primary-orange);
}

.form-button-valid:hover,
.form-button-valid:focus,
.form-button-valid:active {
    background-color: var(--bs-white) !important;
    color: var(--sub-primary-orange) !important;
}

.form-button:focus {
    background-color: transparent !important;
    border: 3px solid var(--bs-white) !important;
    box-shadow: none;
}

.form-button:active {
    background-color: transparent !important;
    border: 3px solid var(--bs-white) !important;
    box-shadow: none;
}

.v-none {
    opacity: 0;
}

.pals-button {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    font-weight: 800;
    font-size: 28px;
    letter-spacing: 2.8px;
    border-radius: 50px;
    width: 363px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    max-width: 100%;
}

.mb-30px {
    margin-bottom: 30px !important;
}

.textdecoration-none {
    /* text-decoration: none !important; */
}

.contact-form-container {
    margin-top: 140px;
}

.px-3px {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.thank-you-container {
    height: 30vh;
}
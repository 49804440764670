@media (min-width: 1500px) {
    .container {
        max-width: 1420px !important;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1520px !important;
    }
}

@media (min-width: 992px) {
    .swiper-button-next {
        display: none !important;
    }
    .swiper-button-prev {
        display: none !important;
    }
}

@media (max-width: 1200px) {
    body {
        font-size: 18px !important;
    }
    .home-pals-logo {
        height: 260px;
    }
    .home-launch-text {
        letter-spacing: 8px !important;
    }
    .instagram-logo {
        height: 70px;
        width: 70px;
    }
    .title-banner-container {
        letter-spacing: 8px;
    }
    .follow-us-instagram {
        font-size: 44px;
    }
    .top-banner-subtext {
        font-size: 35px !important;
        line-height: 45px !important;
        letter-spacing: 2.8px;
    }
    .background-ellipse-position {
        width: 35%;
    }
    .map-check-my-palace {
        height: 600px;
        width: 600px;
    }
    .map-check-my-palace-img {
        width: 450px;
        height: 350px;
    }
    .header-menu-item {
        margin-left: 0px;
    }
}

@media (max-width: 992px) {
    body {
        font-size: 16px !important;
    }
    .custom-btn {
        width: 100%;
        font-size: 22px;
    }
    .home-pals-logo {
        height: 200px;
    }
    .home-launch-text {
        letter-spacing: 6px !important;
    }
    .title-banner-container {
        letter-spacing: 6px;
    }
    .banner-mockup-image {
        width: 360px;
    }
    .background-ellipse-position {
        width: 70%;
    }
    .head-text {
        font-size: 28px;
        letter-spacing: 2.8px;
        font-weight: bold;
        color: var(--sub-primary-orange);
        line-height: 28px;
    }
    .sub-head-text {
        font-size: 49px;
        font-weight: bold;
        color: var(--bs-dark);
        line-height: 62px;
    }
    .sub-text-peragraph {
        line-height: 25px;
    }
    .map-check-my-palace {
        height: 700px;
        width: 700px;
    }
    .map-check-my-palace-img {
        width: 700px;
        height: 550px;
    }
    .form-name-input {
        display: flex;
        justify-content: center;
    }
    .form-email-input {
        display: flex;
        justify-content: center;
    }
    .banner-mobile-container {
        justify-content: center !important;
    }
}

@media (max-width: 768px) {
    body {
        font-size: 14px !important;
    }
    .home-pals-logo {
        height: 170px;
    }
    .home-launch-text {
        letter-spacing: 4px !important;
    }
    .instagram-logo {
        height: 69px;
        width: 69px;
    }
    .top-banner-subtext {
        font-size: 35px !important;
        line-height: 40px !important;
        letter-spacing: 0px;
    }
    .background-ellipse-position {
        width: 70%;
    }
    .margin-top {
        margin-top: 25px;
    }
    .card-icons {
        margin-inline: inherit;
    }
    .margin-top-37 {
        margin-top: 22px;
    }
    .our-mission-list {
        margin-bottom: 22px;
    }
    .map-check-my-palace {
        height: 500px;
        width: 500px;
    }
    .map-check-my-palace-img {
        width: 500px;
        height: 350px;
    }
    .header-menu-item {
        margin-left: 0px;
    }
}

@media (max-width: 576px) {
    body {
        font-size: 8px !important;
    }
    .home-instagram-text {
        font-size: 20px !important;
    }
    .home-launch-text {
        letter-spacing: 3px !important;
        font-size: 55px !important;
        line-height: 60px !important;
        margin-top: 50px !important;
    }
    .title-banner-container {
        letter-spacing: 4px;
    }
    .banner-mockup-image {
        width: 239px;
    }
    .background-ellipse-position {
        width: 100% !important;
    }
    .map-check-my-palace {
        height: 350px;
        width: 350px;
    }
    .map-check-my-palace-img {
        width: 350px;
        height: 250px;
    }
    .flex-direction-reverse {
        display: flex;
        flex-direction: row-reverse;
    }
    .map-check-position {
        display: flex;
        justify-content: center;
    }
    .pal-bandana-position {
        display: flex;
        justify-content: center;
    }
    .form-subtext {
        font-size: 20px;
        margin-top: 28px;
        margin-bottom: 22px;
    }
    .top-banner-heading {
        margin-top: 22px !important;
    }
    .form-bg-color {
        padding-top: 63px;
        padding-bottom: 79px;
    }
    .header-logo {
        max-width: 87px;
    }
    .navbar-logo-hover {
        max-width: 87px !important;
    }
    .header-menu-item {
        margin-left: 0px;
    }
    .blue-circle {
        width: 150% !important;
    }
    .banner-container {
        margin-top: 100px;
    }
    .sticky-navbar-mobile {
        background-color: var(--bs-primary) !important;
    }
}